<template>
  <div class="content">
    <div class="title">邀请有礼</div>
    <div class="desc">现金红包等你拿</div>
    <div class="share-info">
      <div id="copyBtn" @click="copy" class="invite">{{invite}}</div>
      <vue-qr class="myewm" :logoSrc="iconUrl" :text="myUrl" :size="160"></vue-qr>
      <div class="ewm-sb">长按二维码识别</div>
    </div>
    <div class="saveBtn" @click="openApp">下载APP</div>
    <img class="ewm-bottom" src="@/assets/share/erm_bottom.png" />
  </div>
</template>

<script>
import Clipboard from 'clipboard'
import { showToast } from 'vant'
import { getLoginInfo } from '@/api/request'
import vueQr from 'vue-qr/src/packages/vue-qr.vue'
export default {
  name: 'LcvueShareNormal',

  data() {
    return {
      lcID: '',
      invite: ' ',
      myUrl: 'https://ios.liangce.cc/#/',
      iconUrl: require('../../assets/icon.png'),
      isIOS: false
    }
  },
  components: {
    vueQr
  },

  mounted() {
    let geturl = window.location.href
    let getqyinfo = geturl.split('?')[1]
    let getqys = new URLSearchParams(getqyinfo)
    this.lcID = getqys.get('lcID')
    this.getUser()

    let u = navigator.userAgent
    this.isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
  },

  methods: {
    async getUser() {
      if (this.lcID != null && this.lcID.length != 0) {
        let res = await getLoginInfo(this.lcID)
        if (res.code == 200) {
          this.invite = res.data.invite
          // this.myUrl = 'https://ios.liangce.cc/#/?lcID=' + this.invite
        }
      } else {
        // this.$router.push('/redirect')
      }
      // if (this.isIOS) {
      //   this.myUrl = 'https://apps.apple.com/cn/app/id1670811176'
      // } else {
      this.myUrl = 'https://a.app.qq.com/o/simple.jsp?pkgname=com.liangcang.lcapp'
      // }
    },
    goto() {
      this.$router.push({
        path: '/hello'
      })
    },
    copy() {
      // 因为此事件不能添加.stop，所以要设置一个开关, 500ms后打开开关
      let str = this.invite
      let clipboard = new Clipboard('#copyBtn', {
        text: function () {
          return str
        }
      })
      clipboard.on('success', () => {
        showToast('复制成功')
        clipboard.destroy()
      })
    },
    openApp() {
      // window.setTimeout(function () {
      // if (this.isIOS) {
      //   window.location.href = 'https://apps.apple.com/cn/app/id1670811176'
      // } else {
      window.location.href = 'https://a.app.qq.com/o/simple.jsp?pkgname=com.liangcang.lcapp'
      //   }
      // }, 2500)
      // window.location.href = 'lcapp://app'
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  color: #000;
  min-height: 100vh;
  background: url('../../assets/share/share_bg.png') center center no-repeat;
  background-size: 100% 100%;
  position: absolute; //绝对定位
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title {
  font-size: 30px;
  font-family: Source Han Sans CN-Heavy, Source Han Sans CN;
  font-weight: 800;
  color: #ffffff;
  margin-top: 50px;
}
.desc {
  font-size: 16px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #ffffff;
  margin-top: 5px;
  margin-bottom: 15px;
}
.share-info {
  width: 350px;
  height: 420px;
  background-color: green;
  color: #000;
  background: url('../../assets/share/ewm_bg.png') center center no-repeat;
  background-size: 100% 100%;
  .invite {
    font-size: 34px;
    font-weight: 900;
    color: #e13b3b;
    line-height: 40px;
    text-shadow: 0px 4px 10px rgba(65, 4, 2, 0.09);
    margin-top: 13px;
  }
  .myewm {
    margin-top: 56px;
    margin-bottom: 5px;
  }
}
.saveBtn {
  margin-top: 20px;
  width: 164px;
  height: 51px;
  background: #ff4e3d;
  box-shadow: 0px 4px 10px 0px rgba(124, 14, 3, 0.26), inset 0px 1px 2px 0px rgba(252, 252, 252, 0.31);
  border-radius: 116px 116px 116px 116px;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-family: Source Han Sans CN-Bold, Source Han Sans CN;
  font-weight: bold;
  color: #ffffff;
}
.ewm-bottom {
  margin-top: 30px;
  width: 80vw;
}
</style>