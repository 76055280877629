<template>
  <div>
    <div class="content">
      
      <div @click="popShow(3)" class="chooseShop">
        <div class="choose-title">{{ shopSel }}</div>
        <van-icon name="arrow-down" />
      </div>
      <div id="main" ref="chart"></div>
      <div class="choosedate">
        <div class="fanwei">时间范围:</div>
        <div @click="popShow(1)">{{ startDateStr }}</div>
        <van-icon name="arrow-down" />
        <div class="date-sx"></div>
        <div @click="popShow(2)">{{ dateStr }}</div>
        <van-icon name="arrow-down" />
      </div>

      <div class="address">企业剩余车辆信息</div>
      <div class="title">
        <div class="t-name">厂家</div>
        <div class="t-item">{{ yesterday }}</div>
        <div class="t-item">{{ today }}</div>
        <div class="t-item">对比</div>
      </div>

      <div class="car-list">
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <van-list
            v-model:loading="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
          >
            <div class="list" v-for="(item, index) in list" :key="index">
              <div class="t-name">{{ item.factoryName }}</div>
              <div class="t-item">{{ item.countBeforeToday }}</div>
              <div class="t-item">{{ item.countYesterDay }}</div>
              <div class="t-item">
                {{ item.countYesterDay - item.countBeforeToday }}
              </div>
            </div>
            <div class="list" v-show="finished && list.length != 0">
              <div class="t-name">车辆合计</div>
              <div class="t-item">{{ carSum.countBeforeToday }}</div>
              <div class="t-item">{{ carSum.countYesterDay }}</div>
              <div class="t-item">
                {{ carSum.countYesterDay - carSum.countBeforeToday }}
              </div>
            </div>
          </van-list>
        </van-pull-refresh>
      </div>

      <van-popup
        v-model:show="showBottom"
        position="bottom"
        :style="{ height: '30%' }"
      >
        <van-date-picker
          v-show="popType == 1 || popType == 2"
          @cancel="cancel"
          @confirm="confirm"
          :min-date="minDate"
          :max-date="maxDate"
          v-model="currentDate"
          title="选择日期"
        />
        <van-picker
          v-show="popType == 3"
          title="标题"
          :columns="columns"
          @confirm="onConfirm"
          @cancel="onCancel"
        />
      </van-popup>
    <div v-if="isNoVip" class="no-vip-center">
        <div class="center-name">全国车辆情况</div>
        <div class="center-name">历史走势</div>
        <div @click="openVip()" class="center-btn">查看历史价格</div>
      </div>
    </div>
    <div v-if="isNoVip" class="no-vip-cotent">
        <div @click="openVip()" class="bottom-btn">查看更多数据</div>
      </div>
  </div>
</template>

<script>
import {
  Icon,
  Picker,
  List,
  DatePicker,
  Popup,
  PullRefresh,
  showLoadingToast,
  closeToast,
} from "vant";
import * as echarts from "echarts";
import { getCarList, getShopList } from "@/api/request";

export default {
  name: "LcvueCarInfo",

  data() {
    return {
      showBottom: false,
      popType: 0,
      page: 0,
      size: 1000,
      finished: false,
      loading: false,
      refreshing: false,
      today: "",
      yesterday: "",
      dateStr: "",
      startDateStr: "",
      shopSel: "车辆合计",
      selShopId: null,
      currentDate: [],
      startDate: [],
      minDate: new Date(1990, 0, 1),
      maxDate: new Date(),
      carSum: {},
      carNums: [],
      dates: [],
      list: [],
      myChart: null,
      columns: [],
      isNoVip: true,
    };
  },

  components: {
    [DatePicker.name]: DatePicker,
    [Popup.name]: Popup,
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    [Icon.name]: Icon,
    [Picker.name]: Picker,
  },

  mounted() {
    window.setVip = this.setVip;
    let geturl = window.location.href;
    let getqyinfo = geturl.split("?")[1];
    let getqys = new URLSearchParams(getqyinfo);
    localStorage.setItem("token", getqys.get("token"));
    let isvip = getqys.get('isvip');
    if(isvip==1){
      this.isNoVip = false
      document.body.style.overflow = "visible";
    }else{
      this.isNoVip = true
      document.body.style.overflow = "hidden";
    }

    let timeOne = new Date();
    let year = timeOne.getFullYear();
    let month = timeOne.getMonth() + 1;
    let day = timeOne.getDate();
    month = month < 10 ? "0" + month : month;
    day = day < 10 ? "0" + day : day;
    this.currentDate = [`${year}`, `${month}`, `${day}`];
    this.dateStr = `${year}-${month}-${day}`;

    let startTime = new Date(timeOne - 6 * 24 * 3600 * 1000);
    year = startTime.getFullYear();
    month = startTime.getMonth() + 1;
    day = startTime.getDate();
    month = month < 10 ? "0" + month : month;
    day = day < 10 ? "0" + day : day;
    this.startDateStr = `${year}-${month}-${day}`;
    this.getShopList();
  },

  methods: {
    setVip(vip){
      if(vip==1){
        this.isNoVip = false;
        document.body.style.overflow = "visible";
      }
    },
    openVip(){
      APPOpenVip.postMessage('')
    },
    popShow(type) {
      this.popType = type;
      this.showBottom = true;
    },
    onRefresh() {
      this.page = 1;
      this.carNums = [];
      this.list = [];
      this.dates = [];
      this.getList();
    },
    async getShopList() {
      let res = await getShopList(1, 10000);
      if (res.code == 200) {
        let arr = res.data ?? [];
        this.columns.push({
          text: "车辆合计",
          value: null,
        });
        arr.forEach((element) => {
          this.columns.push({
            text: element.factory,
            value: element.id,
          });
        });
      }
    },
    onLoad() {
      this.page++;
      this.loading = true;
      this.getList();
    },
    async getList() {
      if (this.page == 1) {
        showLoadingToast({
          message: "加载中...",
          forbidClick: true,
          loadingType: "spinner",
        });
      }
      let res = await getCarList(
        this.page,
        this.size,
        this.startDateStr,
        this.dateStr,
        this.selShopId
      );
      closeToast();
      this.loading = false;
      this.refreshing = false;
      if (res.code == 200) {
        this.finished = true;
        this.list = this.list.concat(res.data.carList);

        this.carNums = res.data.carNums;
        this.dates = res.data.dates;

        if(this.isNoVip==false){
          this.carSum = res.data.carSum;
        this.today =
          this.carSum.today.split("-")[1] +
          "月" +
          this.carSum.today.split("-")[2] +
          "号";
        this.yesterday =
          this.carSum.yesterday.split("-")[1] +
          "月" +
          this.carSum.yesterday.split("-")[2] +
          "号";
        }
        this.getEchartData();
      } else {
        this.finished = true;
      }
    },
    getEchartData() {
      if (this.myChart == null) {
        this.myChart = echarts.init(document.getElementById("main"));
      }

      if (this.myChart) {
        const option = {
          grid: {
            left: "3%",
            right: "3%",
            bottom: "0%",
            top: "10%",
            containLabel: true,
          },
          dataZoom: [
            {
              type: "slider",
              startValue: 0,
              endValue: 3,
            },
          ],
          color: ["#F1722D", "#2D61F1"],
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: this.dates,
            axisLabel: {
              interval: 0,
            },
          },
          yAxis: {
            type: "value",
          },
          series: [
            {
              type: "line",
              smooth: true,
              data: this.carNums,
            },
          ],
        };
        this.myChart.setOption(option);
        setTimeout(function () {
          window.onresize = function () {
            this.myChart.resize();
          };
        }, 200);
      }
    },
    cancel() {
      this.showBottom = false;
    },
    confirm(res) {
      this.showBottom = false;
      if (this.popType == 1) {
        this.startDateStr = `${res.selectedValues[0]}-${res.selectedValues[1]}-${res.selectedValues[2]}`;
      } else if (this.popType == 2) {
        this.dateStr = `${res.selectedValues[0]}-${res.selectedValues[1]}-${res.selectedValues[2]}`;
      }
      this.onRefresh();
    },
    onConfirm({ selectedIndexes }) {
      this.showBottom = false;
      this.shopSel = this.columns[selectedIndexes[0]].text;
      this.selShopId = this.columns[selectedIndexes[0]].value;
      this.onRefresh();
    },
    onCancel() {
      this.showBottom = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 40px;
  padding-top: 20px;
  position: relative;
   .no-vip-center {
    position: absolute;
    left: 0;
    right: 0;
    top: 20px;
    height: 367px;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.6411) 35%,
      #ffffff 100%
    );
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    margin-bottom: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .center-name {
      font-size: 24px;
      font-family: YouSheBiaoTiHei-Bold, YouSheBiaoTiHei;
      font-weight: 700;
      color: #444444;
      margin-bottom: 10px;
    }
    .center-btn {
      width: 164px;
      height: 37px;
      background: #f07436;
      opacity: 1;
      font-size: 16px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 10px 10px 10px 10px;
    }
  }
}
.no-vip-cotent {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 500px;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.8409) 43%,
      #ffffff 100%
    );
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    .bottom-btn {
      margin-bottom: 43px;
      width: 284px;
      height: 53px;
      background: linear-gradient(270deg, #f6a456 0%, #f1793c 100%);
      border-radius: 17px 17px 17px 17px;
      opacity: 1;
      font-size: 20px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
#main {
  width: 100%;
  height: 350px;
  overflow: auto;
}
.chooseShop {
  width: 200px;
  height: 38px;
  padding: 0 10px;
  background: #f4f5f8;
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #3d3d3d;
  .choose-title {
    flex: 1;
  }
}
.address {
  width: 100%;
  height: 53px;
  background: #f8f8f8;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px solid #dddddd;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #1a051d;
}
.title {
  width: 100%;
  height: 53px;
  background: #f8f8f8;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border-bottom: 1px solid #dddddd;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: rgba(26, 5, 29, 0.6682);
  .t-name {
    flex: 2;
  }
  .t-item {
    flex: 1;
  }
}

.list {
  width: 100%;
  height: 53px;
  border-bottom: 1px solid #dddddd;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #1a051d;
  .t-name {
    flex: 2;
    padding: 0 10px;
  }
  .t-item {
    flex: 1;
  }
}
.choosedate {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 342px;
  height: 38px;
  background: #f4f5f8;
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  font-size: 15px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #51504f;
  .fanwei {
    font-size: 15px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: 700;
    color: #000000;
  }
  .rili {
    width: 25px;
    margin-right: 10px;
  }
  .date-sx {
    width: 0px;
    height: 22px;
    opacity: 1;
    border: 1px solid #bbbbbb;
  }
}

/deep/.van-list {
  width: 100vw;
}
</style>