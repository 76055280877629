<template>
  <div>
    <img mode="scaleToFill" class="def-bg" src="@/assets/share/invite_cg.png" />
    <div class="openBtn" @click="openApp">下载APP</div>
  </div>
</template>

<script>
export default {
  name: 'LcvueInviteSuccess',

  data() {
    return {
      isIOS: false
    }
  },

  mounted() {
    let u = navigator.userAgent
    this.isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
  },

  methods: {
    openApp() {
      // window.setTimeout(function () {
      //   if (this.isIOS) {
      //     window.location.href = 'https://apps.apple.com/cn/app/id1670811176'
      //   } else {
      window.location.href = 'https://a.app.qq.com/o/simple.jsp?pkgname=com.liangcang.lcapp'
      //   }
      // }, 2500)
      // window.location.href = 'lcapp://app'
    }
  }
}
</script>

<style lang="scss" scoped>
.def-bg {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  z-index: -1;
}
.openBtn {
  position: absolute;
  top: 500px;
  left: 40px;
  width: 334px;
  height: 51px;
  background: #fa6e28;
  border-radius: 116px 116px 116px 116px;
  opacity: 1;
  border: 1px solid rgba(255, 255, 255, 0.45);
  font-size: 20px;
  font-family: Source Han Sans CN-Bold, Source Han Sans CN;
  font-weight: 700;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>