<template>
  <div id="shopPriceItem" class="content">
    <div class="jk-body">
      <div class="top-c">
      <div class="top-title">海关数据信息</div>
      <div @click="showBottom=true" class="choosedate">
          <img class="rili" src="@/assets/discover/rili.png" alt />
          <div>{{dateStr}}</div>
        </div>
    </div>
    <div class="bottom-c">
      <div class="bottom-list">
        <div class="title">
          <div class="t-name">商家名称</div>
          <div class="t-item">重量(万吨)</div>
          <div class="t-item">总金额(亿元)</div>
        </div>

        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <van-list v-model:loading="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
            <div class="list" v-for="(item ,index) in list" :key="index">
              <div class="t-item">
                 <div>{{item.commodityName}}</div>
                </div>
              <div class="t-item">
                 <div v-if="isNoVip==false">{{item.commodityCount}}</div>
                <div v-else>
                  <img class="image" src="@/assets/discover/no_vip.png" alt="">
                </div>
                </div>
              <div class="t-item">
                <div v-if="isNoVip==false">{{item.commodityAmount}}</div>
                <div v-else>
                  <img class="image" src="@/assets/discover/no_vip.png" alt="">
                </div>
              </div>
            </div>
          </van-list>
        </van-pull-refresh>
      </div>
    </div>
    </div>

    <div class="pop-date">
      <van-popup get-container="#app" v-model:show="showBottom" position="bottom" :style="{ height: '30%', }">
        <van-date-picker :columns-type="columnsType" @cancel="cancel" @confirm="confirm" :min-date="minDate" :max-date="maxDate" v-model="currentDate" title="选择日期" />
      </van-popup>
    </div>
    <div v-if="isNoVip" class="no-vip">
      <div class="no-vip-cotent">
        <div @click="openVip()" class="bottom-btn"> 我要查看海关数据</div>
        <div @click="openVip()" class="bottom-name">实时更新海关数据</div>
      </div>
    </div>
  </div>
</template>

<script>
import { DatePicker, Popup, PullRefresh, List } from 'vant'
import { getImportList } from '@/api/request'
export default {
  name: 'LcvueShopPriceItem',



  data() {
    return {
      showBottom: false,
      page: 0,
      size: 10,
      finished: false,
      loading: false,
      refreshing: false,
      dateStr: '',
      currentDate: [],
      minDate: new Date(1990, 0, 1),
      maxDate: new Date(),
      list: [],
      isNoVip:true,
      columnsType:['year', 'month']
    }
  },
  components: {
    [DatePicker.name]: DatePicker,
    [Popup.name]: Popup,
    [List.name]: List,
    [PullRefresh.name]: PullRefresh
  },

  mounted() {
    window.setVip = this.setVip;
    let geturl = window.location.href
    let getqyinfo = geturl.split('?')[1]
    let getqys = new URLSearchParams(getqyinfo)
    localStorage.setItem('token', getqys.get('token'))
    let isvip = getqys.get('isvip');
    if(isvip==1){
      this.isNoVip = false
      document.body.style.overflow = "visible";
    }else{
      this.isNoVip = true
      document.body.style.overflow = "hidden";
    }
    let timeOne = new Date()
    let year = timeOne.getFullYear()
    let month = timeOne.getMonth() + 1
    let day = timeOne.getDate()
    month = month < 10 ? '0' + month : month
    day = day < 10 ? '0' + day : day
    this.currentDate = [`${year}`, `${month}`,]
    this.dateStr = `${year}-${month}`
  },

  methods: {
    setVip(vip){
      if(vip==1){
        this.isNoVip = false;
        document.body.style.overflow = "visible";
      }
    },
    openVip(){
      APPOpenVip.postMessage('')
    },
    onRefresh() {
      this.page = 1
      this.list = []
      this.getList()
    },
    onLoad() {
      this.page++
      this.loading = true
      this.getList()
    },
    async getList() {
      let res = await getImportList(this.page, this.size, this.dateStr)
      this.loading = false
      this.refreshing = false
      if (res.code == 200) {
        this.list = this.list.concat(res.data)
        if (res.data.length < this.size) {
          this.finished = true
        } else {
          this.finished = false
        }
      } else {
        this.finished = true
      }
    },
    changeTime(type) {
      this.trendType = type
      this.getPrice()
    },
    cancel() {
      this.showBottom = false
    },
    confirm(res) {
      this.showBottom = false
      this.dateStr = `${res.selectedValues[0]}-${res.selectedValues[1]}`
      this.onRefresh()
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f4f4f4;
}
.no-vip{
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  // z-index: 999999;

  background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.8409) 43%, #FFFFFF 100%);
  // background-color: red;
  .no-vip-cotent{
    width: 100vw;
    height: 459px;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    .bottom-btn{
      width: 284px;
      height: 53px;
      background: linear-gradient(270deg, #F6A456 0%, #F1793C 100%);
      border-radius: 17px 17px 17px 17px;
      opacity: 1;
      font-size: 20px;
     font-family: Source Han Sans CN-Regular, Source Han Sans CN;
     font-weight: 400;
     color: #FFFFFF;
     display: flex;
     justify-content: center;
     align-items: center;
    }
    .bottom-name{
      font-size: 24px;
      font-family: 'YouSheBiaoTiHei';
      font-weight: 400;
      color: #F1783C;
      margin-bottom: 43px;
      margin-top: 20px;
    }
  }
}
.jk-body{
  padding: 10px;
  margin: 10px;
  border-radius: 10px 10px 10px 10px;
}
.top-c {
  border-radius: 10px 10px 0px 0px;
  padding: 15px;
  width: 334px;
  background: #ffffff;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  .top-title {
    width: 86px;
    height: 23px;
    font-size: 20px;
    text-align: left;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: 700;
    color: #000000;
    flex: 1;
  }
   .choosedate {
      width: 120px;
      height: 40px;
      background: #f4f5f8;
      border-radius: 10px 10px 10px 10px;
      opacity: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      font-size: 15px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #51504f;
      .rili {
        width: 25px;
        margin-right: 10px;
      }
    }
}
.bottom-c {
  border-radius: 0px 0px 10px 10px;
  padding: 15px;
  width: 334px;
  background-color: white;
  opacity: 1;
  .bottom-list {
    width: 100%;
    .title {
      width: 100%;
      height: 53px;
      opacity: 1;
      border-bottom: 1px solid #dddddd;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      font-size: 13px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: rgba(26, 5, 29, 0.6682);
      .t-name {
        flex: 1;
      }
      .t-item {
        flex: 1;
      }
    }
    .list {
      width: 100%;
      min-height: 53px;
      border-bottom: 1px solid #dddddd;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      font-size: 15px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 500;
      color: #1a051d;
      .t-item {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        .image{
          width: 60px;
          height: 20px;
        }
      }
    }
  }
}
.b-title-item-img-sz {
  height: 20px;
}
.b-title-num {
  margin-right: 5px;
  font-size: 15px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #1a051d;
}
.item-un {
  margin: 0 10px;
  width: 89px;
  height: 30px;
  background: #ff7a3d;
  border-radius: 116px 116px 116px 116px;
  opacity: 1;
  border: 1px solid #f65e19;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-family: Source Han Sans CN-Bold, Source Han Sans CN;
  font-weight: 700;
  color: #ffffff;
}
.item-sel {
  margin: 0 10px;
  width: 89px;
  height: 30px;
  border-radius: 116px 116px 116px 116px;
  opacity: 1;
  border: 1px solid #f65e19;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-family: Source Han Sans CN-Bold, Source Han Sans CN;
  font-weight: 700;
  color: #ff7a3d;
}
/deep/.van-list {
  width: 100%;
}
</style>